
.menu {

  @media screen and (max-width: 768px) {
    .menu-bar {
      display: none !important;
    }
    .mobile {
      display: block !important;
    }
  }

  .menu-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 50px;
    height: 80px;
    background: #0f0c29;
    z-index: 10;

    .menu-container {
      .menu-item {
        display: inline-block;
        vertical-align: middle;
        margin-right: 40px;

        button {
          background: white;
          outline: unset;
          border: none;
          color: #0f0c29;
          font-size: 14px;
          font-weight: bold;
          padding: 8px 12px;
          border-radius: 20px;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }

        a {
          text-decoration: none;
          color: white;
          cursor: pointer;
          opacity: 0.8;
          font-size: 18px;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

}

.home-content {
  // margin-top: 80px;
  background: #24243e;
  padding-bottom: 100px;


  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
}
