
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}


*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  letter-spacing: 0;
  background-repeat: no-repeat;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
html {
  // height: 100%;
  // height: 100vh;
  font-size: 62.5%; /* Corrects text resizing oddly in IE6/7 when body font-size is set using em units http://clagnut.com/blog/348/#c790 */
  -webkit-text-size-adjust: 100%; /* Prevents iOS text size adjust after orientation change, without disabling user zoom */
  -ms-text-size-adjust:     100%; /* www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/ */
}
body {
  background: #fff; /* Fallback for when there is no custom background color defined. */
  // height: 100%;
  // height: 100vh;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}
ol, ul {
  list-style: none;
}
table { /* tables still need 'cellspacing="0"' in the markup */
  border-collapse: separate;
  border-spacing: 0;
}
caption, th, td {
  font-weight: normal;
  text-align: left;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
}
blockquote, q {
  quotes: "" "";
}
a:focus {
  outline: thin dotted;
}
a:hover,
a:active {
  outline: 0;
}
a img {
  border: 0;
}
/*--------------------------------------------------------------
2.0 Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
textarea {
  color: #404040;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-size: 1.6rem;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  clear: both;
}
p {
  margin-bottom: 0;
}
b, strong {
  font-weight: bold;
}
dfn, cite, em, i {
  font-style: italic;
}
blockquote {
  margin: 0 1.5em;
}
address {
  margin: 0 0 1.5em;
}
pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 14px;
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}
code, kbd, tt, var {
  font: 15px Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}
abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}
mark, ins {
  background: #fff9c0;
  text-decoration: none;
}
sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  bottom: 1ex;
}
sub {
  top: .5ex;
}
small {
  font-size: 75%;
}
big {
  font-size: 125%;
}
/*--------------------------------------------------------------
3.0 Elements
--------------------------------------------------------------*/
hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}
dt {
  font-weight: bold;
}
img {
  height: auto; /* Make sure images are scaled correctly. */
  max-width: 100%; /* Adhere to container width. */
}
figure {
  margin: 0;
  max-width: 100%;
}
table {
  margin: 0 0 1.5em;
  width: 100%;
}
th {
  font-weight: bold;
}
/*--------------------------------------------------------------
4.0 Forms
--------------------------------------------------------------*/
button,
input,
select,
textarea {
  font-size: 100%; /* Corrects font size not being inherited in all browsers */
  margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
  vertical-align: baseline; /* Improves appearance and consistency in all browsers */
}
