.specs-container {
  width: 100%;
  background: #24243e;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // padding: 130px 50px;
  // min-height: 500px;
  min-height: 100vh;
  padding: 30px 20px;

  .game-image-container {
    width: 400px;
    display: inline-block;
    vertical-align: middle;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .specs-concept {
    width: 400px;

    h2 {
      font-size: 35px;
      color: white;
      font-weight: bold;
      margin-bottom: 35px;
    }

    ul {
      li {
        color: white;
        font-size: 16px;
        margin-top: 20px;
        text-align: left;
        list-style: none;
      }
    }
  }


  @media screen and (max-width: 1024px) {
    .specs-image-container {
      width: 250px;
      height: 250px;
    }

    .specs-concept {
      h2 {
        font-size: 30px;
      }

      ul {
        li {
          font-size: 14px;
          list-style: none;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    text-align: center;
    // padding: 50px 50px;

    .game-image-container {
      width: 100%;
      margin: 30px 0 ;
    }

    .specs-concept {
      text-align: center;
      width: 100%;
      padding: 0px;

      h2 {
        font-size: 30px;
      }

      ul {
        li {
          font-size: 14px;
          list-style: none;
        }
      }
    }
  }

  .social-cont {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    .social-badge {
      width: 40px;
      height: 40px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin: 0px 10px;
      cursor: pointer;
      color: #24243e;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
