.roadmap-container {
  width: 100%;
  background: #24243e;
  margin-top: 100px;

  .date-label {
    font-size: 20px;
  }

  .timeline-title {
    font-size: 20px;
    font-weight: bold;
  }
  .timeline-description {
    font-size: 13px;
    color: white;
  }
}
