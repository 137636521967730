.home-block-container {
  height: calc(95vh - 80px);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 40px;

  background: #0f0c29;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .home-block-wrapper, .image-block-wrapper {
    display: inline-block;
    vertical-align: middle;

    h1, h2 {
      text-align: left;
    }

    img {
      width: 600px;
    }
  }

  @media screen and (max-width: 768px) {
    height: auto;
    flex-direction: column;
    padding: 30px 0;

    .home-block-wrapper {
      text-align: center;

      h1, h2 {
        text-align: center;
      }
    }

    .image-block-wrapper {
      margin-top: 30px;
    }

  }

  h1 {
    font-weight: bold;
    font-size: 35px;
    color: white;
  }

  h2 {
    margin-top: 40px;
    width: 450px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: white;

    @media screen and (max-width: 768px) {
      width: 300px;
    }
  }

  .countdown-container {
    margin-top: 40px;

    .countdown-item {
      position: relative;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      padding: 0 10px;

      .countdown-def {
        color: white;
        font-size: 14px;
        font-weight: bold;

        @media screen and (max-width: 768px) {
          font-size: 11px;
        }
      }

      .countdown-value {
        font-size: 70px;
        color: white;
        font-weight: bold;

        @media screen and (max-width: 768px) {
          font-size: 25px;
        }
      }
    }


    .countdown-label {
      font-size: 45px;
      color: white;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  .cta-mint {
    outline: unset;
    border: unset;
    border-radius: 20px;
    padding: 12px 40px;
    font-size: 18px;
    font-weight: bold;
    color: #0f0c29;
    background: white;
    cursor: pointer;
    margin-top: 50px;

    &:hover {
      opacity: 0.8;
    }
  }

  .social-cont {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    .social-badge {
      width: 40px;
      height: 40px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin: 0px 10px;
      cursor: pointer;
      color: #24243e;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
