.specs-container {
  width: 100%;
  background: #24243e;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // padding: 50px;
  // min-height: 600px;
  min-height: 100vh;
  padding: 0 20px;

  .specs-image-container {
    // border: 2px solid white;
    width: 400px;
    height: 400px;
    display: inline-block;
    vertical-align: middle;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .specs-concept {
    width: 400px;

    h2 {
      font-size: 35px;
      color: white;
      font-weight: bold;
      margin-bottom: 35px;
    }

    ul {
      li {
        color: white;
        font-size: 16px;
        margin-top: 20px;
        text-align: left;
        list-style: none;
      }
    }
  }


  @media screen and (max-width: 1024px) {
    .specs-image-container {
      width: 250px;
      height: 250px;
    }

    .specs-concept {
      h2 {
        font-size: 30px;
      }

      ul {
        li {
          font-size: 14px;
          list-style: none;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    text-align: center;

    .specs-image-container {
      width: 250px;
      height: 250px;
      margin: 30px 0;
    }

    .specs-concept {
      text-align: center;
      width: 100%;
      padding: 0px;

      h2 {
        font-size: 30px;
      }

      ul {
        li {
          font-size: 14px;
          list-style: none;
        }
      }
    }

    .cta-mint {
      display: block;
      margin: 0 auto;
    }
  }


  .cta-mint {
    outline: unset;
    border: unset;
    border-radius: 20px;
    padding: 12px 40px;
    font-size: 18px;
    font-weight: bold;
    color: #0f0c29;
    background: white;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

}
